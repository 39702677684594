<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <BlockGroup
          :label="'Base Options'"
          :closed="false"
        >
          <InputWrapper
            type="info"
            :label="node.type"
          />

          <TexturePreview :texture="node.image" />
          <!--
          <InputWrapper
            type="button"
            v-bind:label="'Change Texture'"
            v-bind:value="'Select'"
            v-on:change="onSelect"/> -->
        </BlockGroup>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './NodeSettingMixin'
import MapAssetSelect from '@components/editor/module/inputs/MapAssetSelect'
const TexturePreview = () => import('@components/editor/scene/blocks/TexturePreview')

export default {
  name: 'TextureBlock',

  components: {
    TexturePreview
  },

  mixins: [mixin],

  computed: {

  },

  methods: {
    onSelect (row) {
      this.$bus.$emit('overlay:modal', {
        component: MapAssetSelect,
        props: {
          callback: this.updateMap
        }
      })
    },

    updateMap (event) {
      console.log('EVENT CALLBACK is - ', event)

      let parent = this.gui.getObjectById(this.node.parent_uuid)
      let attribute = this.node.map_type
      let img = event.url

      this.gui.loadImageAndSetToObject(img, parent, attribute)

      // This also needs to emit a special event to update the map relationship
      // in the resources array

      let key = this.node.name
      let type = 'image/jpg'
      if (img.includes('.png')) type = 'image/png' // Gross

      this.$bus.$emit('scene:model:resource-association-updated', { key: key, resource_id: event.id, type: type })
    }
  }

}

</script>
